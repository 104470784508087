<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          title="Sekolah Paling Aktif"
          subtitle="Daftar sekolah paling aktif dalam menggukanan aplikasi E-Smart Literasia"
          :no-add-button="noAddButton"
          :headers="headers"
          :items="schools"
          :search="search"
          :page="page"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          @show-detail="showDetail"
          @live-search="searchHandler"
          @change-page="paginationHandler"
        ></DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'BookPurchaseHistory',
  components: {
    MainCard,
    DataTablePagination,
    SkeletonLoaderTable,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
      },
      noAddButton: true,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Aktifitas', value: 'activity' },
        { text: 'NPSN', value: 'npsn' },
        { text: 'Nama Sekolah', value: 'name' },
        { text: 'Jenjang', value: 'jenjang' },
        { text: 'Kecamatan', value: 'district' },
        { text: 'Kabupaten/Kota', value: 'regency' },
        { text: 'Aksi', value: 'detail-link' },
      ],
      page: 1,
      search: '',
      totalItems: 10,
      schools: [],
      school: {},
      school_uuid: '',
      service: 'schoolactive',
      totalPages: 0,
      isLoadingData: false,
      isLoadingTable: true,
      modalDialog: false,
      filterQuery: {
        search: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.listActiveSchool()
      },
    },
  },
  mounted() {
    this.listActiveSchool()
  },
  methods: {
    showDetail() {
      this.modalDialog = true
    },
    async listActiveSchool(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
      }).then(({ data }) => {
        this.schools = data.data.map((school, index) => ({
          ...school,
          index: index + 1,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
      this.isLoadingTable = false
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listActiveSchool(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
